import { sort } from '<%CDN_BASE_URL%>/fast-sort/v.3.4.0/fast-sort.esm.js';
import type Field from '/nt/service-worker/dependencies/o365.modules.DataObject.Field.ts';

export enum SortDirection {
    Desc = 'desc',
    Asc = 'asc'
};

export enum GroupByAggregates {
    Avg = "AVG",
    Count = "COUNT",
    Count_Distinct = "COUNT_DISTINCT",
    Sum = "SUM",
    Max = "MAX",
    Min = "MIN"
};

export interface ISortColumns {
    name?: string;
    sortOrder: number;
    sortDirection: SortDirection;
};

export interface IGroupByColumns {
    name: string;
    groupByOrder: number;
};

export interface IGroupByAggregates {
    name: string;
    groupByAggreGate
};

export class DataObjectFieldUtilities {
    static getSortColumns(fields: Array<Field>): Array<ISortColumns> {
        const sortColumns = fields.map(field => {
            let name = null;
            if ( "sortOrder" in field && "sortDirection" in field ) {
                if ( "alias" in field ) {
                    name = field.alias;
                } 
                else if ( "name" in field ) {
                    name = field.name;
                }
                const { sortOrder, sortDirection = "asc" } = field;
                return { name, sortOrder, sortDirection };
            }
        }).filter(item => {
            return item && (item.hasOwnProperty("sortOrder") && item.sortOrder !== null);
        });
        
        return sortColumns;
    }

    static getGroupByAggregates(fields: Array<Field>): Array<IGroupByAggregates> {
        const groupByAggregates: any = fields.map(field => {
            if ("groupByAggregate" in field) {
                const { name, groupByAggregate, alias } = field;
                return { name, groupByAggregate, alias};
            }
        }).filter(item => item);
        return groupByAggregates;
    }

    static getGroupByColumns(fields: Array<Field>): Array<IGroupByColumns> {
        const groupByColumns: Array<any> = fields.map(field => {
            if ("groupByOrder" in field) {
                const { name, groupByOrder} = field;
                return { name, groupByOrder }
            }
        }).filter(item => item);

        return sort(groupByColumns).asc(u => u["groupByOrder"]);
    }

    static getSortingArray(sortColumns: Array<ISortColumns>): Array<any> {
        if (sortColumns.length === 1) {
            const sortBy = sortColumns[0].name;
            const direction = sortColumns[0].sortDirection;
            return this._getSortDirectionArray(direction, sortBy)
        }
        else if (sortColumns.length > 1) {
            const sortArray = sortColumns.map(item => this._getSortDirectionArray(item.sortDirection, item.name));
            return sortArray;
        }
    }

    private static _getSortDirectionArray(direction, sortBy) {
        if (direction == SortDirection.Desc) {
            return [{ desc: (x) => x[sortBy] }];
        }
        else if (direction == SortDirection.Asc) {
            return [{ asc: (x) => x[sortBy] }];
        }
    }
}